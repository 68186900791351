import hamburger from "@/modules/microweber/hamburger.js";
import '@~/big/template.scss';

const mw = {};
hamburger(mw);
addEventListener('DOMContentLoaded', e => {
    mw.MWSiteMobileMenu({
        threshold: 1024,
        size: '25px',
        color: 'var(--primaryColor)',
    }, 5);
});
